export const getFirstDayOfMonth = () => {
  const currentDate = new Date();
  const firstDayOfMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    1
  );

  return {
    firstDay: firstDayOfMonth,
  };
};
