import React from "react";
import { Drawer, Typography, Avatar, Space } from "antd";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";

export const CalculationDetailPremiusModal = ({
  openDetails,
  setOpenDetails,
  viewDataFilter,
}) => {
  return (
    <Drawer
      open={openDetails?.open}
      onCancel={() => {
        setOpenDetails({ open: false });
      }}
      onClose={() => {
        setOpenDetails({ open: false });
      }}
      footer={null}
      title={
        <Typography.Title style={{ margin: 0 }} level={3}>
          Детализация расчета
        </Typography.Title>
      }
      width={500}
      maskClosable={true}
    >
      <div
        style={{
          display: "flex",
          gap: 45,
          alignItems: "center",
          marginBottom: 15,
        }}
      >
        <div style={{ display: "flex", gap: 12, alignItems: "center" }}>
          <Avatar
            style={{ width: 25, minWidth: 25, height: 25 }}
            src={openDetails?.record?.master?.y_avatar}
          />
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ textWrap: "nowrap" }}>
              {openDetails?.record?.master?.y_name}
            </span>
            <span
              style={{
                color: "#8B8B8B",
                fontSize: 12,
                lineHeight: "14.52px",
                textWrap: "nowrap",
              }}
            >
              {openDetails?.record?.master?.y_specialization}
            </span>
          </Space>
        </div>
        <span style={{ fontSize: 16, fontWeight: 700 }}>
          {openDetails?.record?.total?.toLocaleString("ru-RU")} р.
        </span>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 5,
          marginBottom: 22,
        }}
      >
        {openDetails?.record?.schemes?.map((item) => {
          return (
            <>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  margin: 0,
                  paddingLeft: 18,
                }}
              >
                {item?.scheme?.name}
              </p>
              <p style={{ fontSize: 12, margin: 0, paddingLeft: 18 }}>
                {viewDataFilter}
              </p>
              {item?.scheme?.bonus_fix_from_revenue && (
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    backgroundColor: "#4D7CD633",
                    padding: "22px",
                    borderRadius: 5,
                    margin: 0,
                  }}
                >
                  Фиксированная сумма бонуса:
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {" "}
                    {item?.scheme?.bonus_fix_from_revenue?.toLocaleString(
                      "ru-RU"
                    )}{" "}
                    р.
                  </span>
                </p>
              )}
              {item?.scheme?.porog && (
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    backgroundColor: "#4D7CD633",
                    padding: "22px",
                    borderRadius: 5,
                    margin: 0,
                  }}
                >
                  Порог оборота:
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {" "}
                    {item?.scheme?.porog?.toLocaleString("ru-RU")} р.
                  </span>
                </p>
              )}
              {item?.scheme?.bonus_persent_from_revenue && (
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    backgroundColor: "#4D7CD633",
                    padding: "22px",
                    borderRadius: 5,
                    margin: 0,
                  }}
                >
                  Процент от оборота бонусом:
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {" "}
                    {item?.scheme?.bonus_persent_from_revenue}
                  </span>
                </p>
              )}
              {item?.scheme?.reducing_revenue && (
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    backgroundColor: "#4D7CD633",
                    padding: "22px",
                    borderRadius: 5,
                    margin: 0,
                  }}
                >
                  На сколько уменьшать оборот:
                  <span
                    style={{
                      fontSize: 12,
                      fontWeight: 400,
                      margin: 0,
                    }}
                  >
                    {" "}
                    {item?.scheme?.reducing_revenue?.toLocaleString("ru-RU")} р.
                  </span>
                </p>
              )}
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  backgroundColor: "#4D7CD633",
                  padding: "22px",
                  borderRadius: 5,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                Уменьшение премиального оборота за срок работы:{" "}
                {item?.scheme?.reduce_revenue_with_master_works ? (
                  <img src={iconSuccessful} alt="Есть" />
                ) : (
                  <img src={iconNotSuccessful} alt="Нет" />
                )}
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  backgroundColor: "#4D7CD633",
                  padding: "22px",
                  borderRadius: 5,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                Вычет гарантийных переделок из оборота:{" "}
                {item?.scheme?.corrs_from_revenue ? (
                  <img src={iconSuccessful} alt="Есть" />
                ) : (
                  <img src={iconNotSuccessful} alt="Нет" />
                )}
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  backgroundColor: "#4D7CD633",
                  padding: "22px",
                  borderRadius: 5,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                Бонус за оборот:{" "}
                {item?.scheme?.bonus_by_revenue ? (
                  <img src={iconSuccessful} alt="Есть" />
                ) : (
                  <img src={iconNotSuccessful} alt="Нет" />
                )}
              </p>
              <p
                style={{
                  fontSize: 16,
                  fontWeight: 700,
                  backgroundColor: "#4D7CD633",
                  padding: "22px",
                  borderRadius: 5,
                  margin: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 5,
                }}
              >
                Добавлять проданные товары в премиальный оборот:{" "}
                {item?.scheme?.add_goods_in_rev ? (
                  <img src={iconSuccessful} alt="Есть" />
                ) : (
                  <img src={iconNotSuccessful} alt="Нет" />
                )}
              </p>
              {item?.scheme?.reducing_revenue_period && (
                <p
                  style={{
                    fontSize: 16,
                    fontWeight: 700,
                    backgroundColor: "#4D7CD633",
                    padding: "22px",
                    borderRadius: 5,
                    margin: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    gap: 5,
                  }}
                >
                  {`Уменьшать оборот каждые ${item?.scheme?.reducing_revenue_period} дней работы`}
                </p>
              )}
            </>
          );
        })}
      </div>
    </Drawer>
  );
};
