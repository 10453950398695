import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { Complaints } from "../../pages/Complaints";
import { Corrections } from "../../pages/Corrections";
import { Payments } from "../../pages/Payments";
import { ComplaintsNetwork } from "../../pages/ComplaintsNetwork";
import { CorrectionsNetwork } from "../../pages/CorrectionsNetwork";
import { Login } from "../../pages/Login";
import { ResetPassword } from "../../pages/ResetPassword";
import ProtectedRoute from "../../utils/ProtectedRoute";
import { Layout } from "antd";
import { LayoutPage } from "../LayoutPage";
import { ChangingPassword } from "../../pages/ChangingPassword";
import { SettingsStudio } from "../../pages/SettingsStudio";
import { UserManagement } from "../../pages/UserManagement";
import { TypesComplaints } from "../../pages/TypesComplaints";
import { StaffPage } from "../../pages/StaffPage";
import { StaffCreatePage } from "../../pages/StaffCreatePage";
import { StaffEditPage } from "../../pages/StaffEditPage";
import { StaffNetworkPage } from "../../pages/StaffNetworkPage";
import { StaffNetworkEditPage } from "../../pages/StaffNetworkEditPage";
import { CalculationPage } from "../../pages/CalculationPage";
import { CalculationNetwork } from "../../pages/CalculationNetwork";
import { СalculationPremiumsPage } from "../../pages/СalculationPremiums";
import { СalculationPremiumsNetworkPage } from "../../pages/СalculationPremiumsNetwork";
import { AwardSchemesPage } from "../../pages/AwardSchemesPage";
import { SettingsAdminPage } from "../../pages/SettingsAdminPage";
import { SettingsPlanningPage } from "../../pages/SettingsPlanningPage";
import { ExpensesPage } from "../../pages/ExpensesPage";
import { PlanningPage } from "../../pages/PlanningPage";
import { useSelector } from "react-redux";
import { getUserData } from "../../store/user/selectors";

function App() {
  const userData = useSelector(getUserData);

  return (
    <Layout
      style={{
        height: "100vh",
        gap: "10px",
        backgroundColor: "#F9F9F9",
        position: "relative",
      }}
    >
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={`/complaints/${userData?.user_studios[0]?.y_id}`} />
          }
        />
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<ResetPassword />} />
        <Route
          path="/staff/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="staff">
                <StaffPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staff_create/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="staff_create">
                <StaffCreatePage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staff_edit/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="staff_edit">
                <StaffEditPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staff_network/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="staff_network">
                <StaffNetworkPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/staff_network_edit/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="staff_network_edit">
                <StaffNetworkEditPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/complaints/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="complaints">
                <Complaints />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/corrections/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="corrections">
                <Corrections />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/payments/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="payments">
                <Payments />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/calculation/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="calculation">
                <CalculationPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/calculation_network/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="calculationNetwork">
                <CalculationNetwork />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/award_schemes/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="award_schemes">
                <AwardSchemesPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/сalculation_premiums/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="сalculation_premiums">
                <СalculationPremiumsPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/сalculation_premiums_network/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="сalculation_premiums_network">
                <СalculationPremiumsNetworkPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="settings">
                <SettingsStudio />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings_planning"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="settings_planning">
                <SettingsPlanningPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/planning/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="planning">
                <PlanningPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route path="/changing" element={<ChangingPassword />} />
        <Route
          path="/user_management"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="user">
                <UserManagement />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/types_complaints"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="types">
                <TypesComplaints />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings_admin"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="admin">
                <SettingsAdminPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/complaints_network/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="complaintsNetwork">
                <ComplaintsNetwork />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/corrections_network/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="correctionsNetwork">
                <CorrectionsNetwork />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
        <Route
          path="/expenses/:id"
          element={
            <ProtectedRoute>
              <LayoutPage pageId="expenses">
                <ExpensesPage />
              </LayoutPage>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Layout>
  );
}

export default App;
