import { createColumn } from "../../utils/utils";
import styles from "./styles.module.css";
import { Space, Tooltip, Avatar } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { formatDateString } from "../../utils/utils";
import iconNotSuccessful from "../../images/iconNotSuccessful.svg";
import iconSuccessful from "../../images/iconSuccessful.svg";

export const fired = [
  { name: "Уволенные", bol: true },
  { name: "Не уволенные", bol: false },
];

export const chain = [
  { name: "Сетевые сотрудники", bol: true },
  { name: "Не сетевые сотрудники", bol: false },
];

export const columns = ({ ordering, setOpenStaffDelete }) => {
  return [
    createColumn(
      "",
      "notification",
      (v) => {
        return v ? (
          <div
            style={{ width: 12, height: 12, backgroundColor: "#76CEC9" }}
          ></div>
        ) : (
          ""
        );
      },
      30
    ),
    createColumn(
      "Сеть",
      "y_chain",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="В сети" />
        ) : (
          <img src={iconNotSuccessful} alt="Не в сети" />
        );
      },
      70
    ),
    createColumn(
      "Сотрудник",
      undefined,
      (_, row) => {
        return (
          <div
            style={{
              display: "flex",
              gap: 12,
              alignItems: "center",
            }}
          >
            <Avatar
              style={{
                width: 25,
                minWidth: 25,
                height: 25,
              }}
              src={row?.y_avatar}
            />
            <Space direction="vertical" style={{ gap: 0 }}>
              <span style={{ textWrap: "nowrap" }}>{row?.y_name}</span>
              <span
                style={{
                  color: "#8B8B8B",
                  fontSize: 12,
                  lineHeight: "14.52px",
                  textWrap: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "170px",
                  display: "flex",
                }}
              >
                {row?.position?.y_title}
              </span>
            </Space>
          </div>
        );
      },
      100,
      undefined,
      ordering.y_name,
      () => {
        return 0;
      }
    ),
    createColumn(
      "Нанят",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.hiring_date)}
          </span>
        );
      },
      100
    ),
    createColumn(
      "Уволен",
      undefined,
      (_, row) => {
        return (
          <span style={{ color: "#4B4F55", textWrap: "nowrap" }}>
            {formatDateString(row?.y_dismissal_date)}
          </span>
        );
      },
      100
    ),
    createColumn("Рейтинг", "y_rating", undefined, 100),
    createColumn(
      "Статус",
      "y_fired",
      (v) => {
        return !v ? "Работает" : "Уволен";
      },
      100
    ),
    createColumn(
      "Схема премирования",
      "master_bonusschemes",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            {v.map((item) => {
              return (
                <span
                  style={{ color: "#4B4F55", textWrap: "nowrap" }}
                  key={item?.id}
                >
                  {item?.scheme?.name}
                </span>
              );
            })}
          </Space>
        );
      },
      150
    ),
    createColumn(
      "Пользователь",
      "user",
      (v) => {
        return (
          <Space direction="vertical" style={{ gap: 0 }}>
            <span style={{ textWrap: "nowrap" }}>
              {v?.external_data?.y_name}
            </span>
            <span
              style={{
                color: "#8B8B8B",
                fontSize: 12,
                lineHeight: "14.52px",
                textWrap: "nowrap",
              }}
            >
              {v?.external_data?.y_phone && `+${v?.external_data?.y_phone}`}
            </span>
          </Space>
        );
      },
      100
    ),
    createColumn(
      "Работает официально",
      "is_official",
      (v) => {
        return v ? (
          <img src={iconSuccessful} alt="Официально" />
        ) : (
          <img src={iconNotSuccessful} alt="Не официально" />
        );
      },
      100
    ),
    createColumn(
      "ТГ сотрудника",
      "user",
      (v) => {
        return v?.external_data?.tg_channel ? (
          <img src={iconSuccessful} alt="Есть" />
        ) : (
          <img src={iconNotSuccessful} alt="Нет" />
        );
      },
      100
    ),
    createColumn(
      "",
      "action",
      (_, v) => {
        return (
          <Tooltip
            color="#FFFFFF"
            title={<span style={{ color: "#4B4F55" }}>Удалить сотрудника</span>}
          >
            <DeleteOutlined
              onClick={(evt) => {
                evt.stopPropagation();
                setOpenStaffDelete({ open: true, data: v });
              }}
              className={styles.icon}
            />
          </Tooltip>
        );
      },
      50
    ),
  ];
};
