import { useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { getUser, setClearUser } from "../store/user";
import { jwtDecode } from "jwt-decode";

function ProtectedRoute({ children }) {
  const dispatch = useDispatch();
  const token = useMemo(() => localStorage.getItem("accessToken"), []);

  useEffect(() => {
    if (token) {
      dispatch(getUser());
    } else {
      dispatch(setClearUser());
    }
  }, [token]);

  const isTokenValid = (token) => {
    try {
      const decoded = jwtDecode(token);
      const currentTime = Date.now() / 1000;
      return decoded.exp > currentTime;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  if (!token || !isTokenValid(token)) {
    localStorage.removeItem("accessToken");
    return <Navigate to="/login" />;
  }

  return children;
}
export default ProtectedRoute;
